<template>
  <div class="faq">
    <div class="faq-list">
      <div class="content">
        <div class="faq-item" v-for="(item,index) in list" @click="getFAQdetail(item)" :key="index">
          <div class="item" v-if="item.id>4">
            <div class="left">
              <img :src="imgUrl+item.icon" alt="" />
              <div>
                <p>{{item.name}}</p>
                <p>{{item.remark}}</p>
              </div>
            </div>
            <div class="arrow"><img  src="@/assets/icons/arrow-right.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllAboutCategory,getAboutCategory} from '@/api/question'
import baseURL from "@/utils/config";
import i18n from '@/i18n' 

export default {
  data() {
    return {
      list:[],
      imgUrl:baseURL.imgUrl
    }
  },
  created(){

  },
  mounted() {
    if( this.$route.query.langEnv==2){
    i18n.global.locale.value = 'en'
  }else if(this.$route.query.langEnv==3){
    i18n.global.locale.value = 'zh'
  }
      this.getList()
      document.title=this.$t('常見問題')
  },
  methods:{
  async getFAQdetail(item){
      const res=  await  getAboutCategory({id:item.id},this.$route.query.langEnv)
      if(res.data.length===1){
        this.$router.push(`/about?id=${res.data[0].id}&langEnv=${this.$route.query.langEnv}`)

      }else{

        this.$router.push({
          path: `/questionDetail/${item.id}/${item.name}`,
          query:{
            langEnv:this.$route.query.langEnv
          }
        })

      }
    },
   async getList(){
       const res= await getAllAboutCategory({},this.$route.query.langEnv)
       this.list=res.data
    }
  }
};
</script>

<style lang="scss" scoped>
.faq {
  .faq-list {
    padding: 16px 10px;
    .content {
      background: #fff;
      border-radius: 12px 12px 12px 12px;
      .faq-item {
        .item {
          padding: 32px 16px 0px 16px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f8f8f8;
          padding-bottom: 20px;
          justify-content: space-between;
        }
        .left {
          display: flex;
          align-items: center;
          img{
            width: 48px;
            height: 48px;
            margin-right: 10px;
          }
        }
        .arrow{
          width: 16px;
        }
      }
    }
  }
}
</style>
